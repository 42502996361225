/* eslint-disable @typescript-eslint/naming-convention */
import { CircularProgress, Paper } from '@mui/material';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import exercises from '../exercises';

declare global {
  interface Window { vcs: any; }
}

const VcMap = (): React.ReactElement => {
  const { id } = useParams();
  const navigate = useNavigate();

  const mapRef = React.useRef<any>();
  const [initialized, setInitialized] = React.useState(false);
  const [loading, _setLoading] = React.useState(true);
  const loadingRef = React.useRef(loading);

  const setLoading = (data: boolean): void => {
    _setLoading(data);
    loadingRef.current = data;
  };

  const { vcs } = window;

  React.useEffect(() => {
    if (!initialized) {
      const exercise = exercises.find((ex) => ex.id === id);
      if (!exercise) {
        navigate('/');
      } else {
        if (exercise.customCss) {
          const cssElm = document.createElement('link');
          cssElm.setAttribute('id', 'custom-css');
          cssElm.setAttribute('rel', 'stylesheet');
          cssElm.setAttribute(
            'href',
            exercise.customCss as string || '/',
          );
          document.head.appendChild(cssElm);
        }
        if (exercise.customJs) {
          const jsElm = document.createElement('script');
          jsElm.setAttribute('id', 'custom-js');
          jsElm.setAttribute(
            'src',
            exercise.customJs as string || '/',
          );
          jsElm.setAttribute('async', '');
          document.head.appendChild(jsElm);
        }


        vcs.vcm.Framework.init(exercise.config);
        setTimeout(() => {
          const framework = vcs.vcm.Framework.getInstance();
          framework.setLocale('nl');
          if (framework.isInitialized) {
            setLoading(false);
          } else {
            framework.subscribeOnce('FRAMEWORK_INITIALIZED', () => {
              setLoading(false);
            });
          }
        }, 1000);
      }
    }

    setInitialized(true);

    return () => {
      try {
        const cssElm = document.getElementById('custom-css') as HTMLLinkElement;
        if (cssElm && cssElm.parentElement) {
          cssElm.parentElement.removeChild(cssElm);
        }
        const jsElm = document.getElementById('custom-js') as HTMLScriptElement;
        if (jsElm && jsElm.parentElement) {
          jsElm.parentElement.removeChild(jsElm);
        }

        const instance = vcs.vcm.Framework.getInstance();
        instance.destroy();
        vcs.vcm.Framework.destroy();
      } catch (e: any) {
        console.log('destroy failed', e.message);
      }
    };
  }, []);

  return (
    <>
      {loading && <Paper elevation={3} style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
        <CircularProgress size={80} variant="indeterminate" thickness={1.8}/>
      </Paper>}
      <Paper
        elevation={4}
        style={{
          position: loading ? 'absolute' : 'relative',
          left: loading ? '100vw' : 0,
          height: '100%',
          // position: 'absolute',
          // right:'100vw',
        }}
      >
        <div
          ref={mapRef}
          id="vcs_map_container"
          style={{ width: '100%', height: '100%' }}
        />
      </Paper>
    </>);
};

export default VcMap;
