import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Rating from '@mui/material/Rating';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';

import exercises from '../exercises';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const WrappedItem = ({
  id,
  rating,
  title,
  subtitle,
  img,
  tags,
  example }: { id: number, rating: number, title: string, subtitle: string, img: string, tags: string[], example: boolean },
): React.ReactElement => (
  <Item elevation={6}>
    <ImageListItem
      style={{
        // background: '#000',
      }}>
      <Link to={`/${id}`}>
        <img
          src={`${img}`}
          alt={`opgave ${id}`}
          style={{
            maxWidth: '100%',
            height: 360,
            // filter: 'grayscale(100%)',
          }}
        />
      </Link>
      <ImageListItemBar
        position="top"
        title={<Rating precision={0.5} value={rating} readOnly />}
        subtitle={
          <div style={{ position: 'absolute', top: 40, right: 10 }}>
            {tags.map((tag: string) => (
              <React.Fragment key={tag}>
                <Chip
                  style={{ lineHeight: 2 }}
                  label={tag}
                  color={example ? 'secondary' : 'primary'}
                /><span>&nbsp;</span>
              </React.Fragment>
            ))}
          </div>
        }
      >
      </ImageListItemBar>

      <ImageListItemBar
        title={title}
        subtitle={
          <Typography variant="body2" component="span" style={{ fontSize: 12 }}>
            {subtitle}
          </Typography>
        }
      />
    </ImageListItem>
  </Item>
);

const Home = (): React.ReactElement => (
  <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Workshop 3D | Future Insight
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
    <Box style={{ marginTop: 64, paddingTop: 0, paddingBottom: 24 }} sx={{ width: '100%' }}>

      <Grid style={{ padding: 24 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} >
          <Paper
            style={{
              padding: 20,
              marginTop: 0,
            }}
            elevation={2}
          >
            <div style={{
              position: 'absolute',
              top: 20,
              right: 30,
              zIndex: 1100,
            }}>
              <a href="https://www.nederlandin3d.nl" target="_blank">
                <img src="/global/landsgrens.png" width="145" alt="nlin3d" />
                <Typography
                  variant="h5"
                  style={{
                    position: 'absolute',
                    top: 44,
                    color: '#000',
                    width: 180,
                    right: -20,
                    textShadow: '2px 2px 2px #ccc',
                  }}>
                  Nederland in 3D
                </Typography>
              </a>
            </div>
            <Typography variant="h5">Opdrachten</Typography>
            <br />
            <Typography variant="body1">
              Welkom bij de 3D workshop van Future Insight! Hieronder staan {`${exercises.filter((e) => !e.hide).length}`} opdrachten,
              varierend van redelijk eenvoudig (<Rating readOnly value={1} size="small" />) tot best wel pittig (<Rating readOnly value={5} size="small" />).
            </Typography>

            <Typography variant="body1">
              Klik op de foto van een opgave om direct te beginnen. Bij elke opgave staan overigens hints, maar probeer het eerst zelf!
            </Typography>

            <br />
            <Typography variant="body1">
              En neem ook eens een kijkje bij deze 3D omgevingen:
            </Typography>
            <br />
            <Typography variant="body1">
              <Stack sx={{ mb: 1 }} direction="row" spacing={1} component="span">
                <Chip icon={<OpenInNewIcon />} target="_blank" label="Nederland In 3D" color="secondary" component="a" href="https://www.nederlandin3d.nl" clickable />
                <Chip icon={<OpenInNewIcon />} target="_blank" label="Rotterdam 3D" color="secondary" component="a" href="https://www.3drotterdam.nl" clickable />
                {/* <Chip icon={<OpenInNewIcon />} target="_blank" label="Ruggedised" color="secondary" component="a" href="https://www.3drotterdam.nl/ruggedised" clickable /> */}
                {/* 
                </Stack>
                <Stack direction="row" spacing={1} component="span"> 
                */}
                <Chip icon={<OpenInNewIcon />} target="_blank" label="Den Haag 3D" color="secondary" component="a" href="https://www.nederlandin3d.nl/denhaag" clickable />
                <Chip icon={<OpenInNewIcon />} target="_blank" label="Groningen 3D" color="secondary" component="a" href="https://www.nederlandin3d.nl/groningen" clickable />
              </Stack>
            </Typography>
            {/* <br />
            <Typography variant="body1">
              Let op. Er wordt uitsluitend gebruik gemaakt van data die publiek beschikbaar is via het internet. 
            </Typography> */}
          </Paper>
        </Grid>
        {exercises
          .filter((e) => !e.hide)
          .map(({ id, title, subtitle, rating, img, tags = [], example = false }) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
              <WrappedItem
                id={id}
                title={title}
                subtitle={subtitle}
                rating={rating}
                img={img}
                tags={tags}
                example={example}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  </>
);

export default Home;
