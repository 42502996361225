import { createTheme, Theme } from '@mui/material/styles';
import { PaletteMode, ThemeOptions } from '@mui/material';

const clearlyTheme = (mode: PaletteMode): ThemeOptions => ({
  typography: {
    fontFamily: [
      '"Open Sans"',
      '"Segoe UI"',
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    mode,
    primary: {
      light: 'hsl(193,63%,66%)',
      main: 'hsl(192,47%,46%)',
      dark: 'hsl(190,100%,25%)',
      contrastText: 'hsl(0,0%,100%)',
    },

    secondary: {
      light: 'hsl(140,68%,64%)',
      main: 'hsl(145,63%,42%)',
      dark: 'hsl(145,100%,25%)',
      contrastText: '#fff',
    },

    divider: 'hsl(0, 0%, 93%)',
  },
});

const lightTheme = createTheme(clearlyTheme('light'));
const darkTheme = createTheme(clearlyTheme('dark'));

const themes: { [key: string]: Theme } = {
  light: lightTheme,
  dark: darkTheme,
};
export default themes;
