import nederlandin3d from './nederlandin3d.json';
import liefdesbrief from './liefdesbrief.json';
import rotterdam from './rotterdam.json';
import rotterdam_full from './rotterdam_full.json';
import denhaag from './denhaag.json';
import schaduw from './schaduw.json';
import waariswally from './waariswally.json';
import instellingen from './instellingen.json';
import bimdenhaag from './bimdenhaag.json';
import parkeergarage from './parkeergarage.json';
import sensoren from './sensoren.json';
import attributen from './attributen.json';
import bim from './bim.json';
import ondergrond from './ondergrond.json';
import intro from './intro.json';
import samenwerkworkshop from './samenwerkworkshop.json';

export default {
  nederlandin3d,
  liefdesbrief,
  rotterdam,
  denhaag,
  schaduw,
  waariswally,
  instellingen,
  bimdenhaag,
  parkeergarage,
  sensoren,
  attributen,
  bim,
  ondergrond,
  intro,
  samenwerkworkshop,
  rotterdam_full,
};
